import React from "react"
import { graphql } from 'gatsby'
import { get } from 'lodash'

import Layout from "layout/layout"
import SEO from "components/seo"
import BookView from 'components/cards/BookView'

const PagePadding = {
  padding: `0 0 32px 0`,
}

const ContentBlockStyle = {
  display: `flex`,
  flexWrap: `wrap`,
  width: `100%`,
  padding: `0 0 40px 0`,
}

class BookPage extends React.Component {

  render() {
    const books = get(this, 'props.data.allContentfulBook.edges').map(edge => ( edge.node ))

    return (
      <Layout>
        <div className="container">
          <div className="row">
            <div className="col-12" style={PagePadding}>
              <SEO title={`Books`} />
              <h1>Books</h1>
              <p>A collection of helpful books I've found or have been recommended over the years. They range from general "intro to design" books to specific resources for various disciplines – typography, logos, interaction design, etc.</p>
            </div>

            <div style={ContentBlockStyle}>
              {
                books.map(book => (
                  <BookView key={book.id} book={book} />
                ))
              }
            </div>

          </div>
        </div>
      </Layout>
    );
  }
};

export default BookPage

export const pageQuery = graphql`
  query BooksQuery {
    allContentfulBook {
      edges {
        node {
          id
          resourceType
          title
          authors
          link
          bookCover {
            title
            file {
              url
            }
          }
          deconstructingDesignSlug
          tags
        }
      }
    }
  }
`
